<template>
  <!-- eslint-disable -->
  <b-sidebar
    id="add-new-speciality"
    :visible="isAdd"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    no-close-on-backdrop
    @change="(val) => $emit('update:is-add', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ isEdit ? "Editar" : "Agregar nuevo" }} Usuario</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="[hide(), resetForm()]"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ invalid }" ref="refFormObserver">
        <!-- Form -->

        <b-form class="p-2" @submit.prevent="onSubmit(items)">
          <validation-provider #default="{ errors }" name="userphoto" rules="">
            <b-form-group
              label="Foto de Perfil"
              label-for="aplication-img"
              class="mb-0"
            >
              <div class="d-flex flex-row justify-content-center w-100 h-100">
                <b-media
                  class="mb-2 flex-column flex-xl-row w-100"
                  id="aplication-img"
                >
                  <template #aside class="w-100">
                    <div class="d-flex justify-content-center w-100">
                      <b-avatar
                        ref="previewEl"
                        :variant="`light-info`"
                        size="120px"
                        class="avatar_img relative"
                        rounded
                      >
                        <img
                          class="w-100 h-100"
                          :src="
                            tempUrl != ''
                              ? apiurl + tempUrl
                              : urlFile
                              ? urlFile
                              : sideImg
                          "
                          alt=""
                        />
                      </b-avatar>
                    </div>
                  </template>
                </b-media>
                <div class="box_buttons d-flex flex-column mt-1 w-100">
                  <div class="text-center w-100">
                    <b-button
                      @click.prevent="deleteImage(true)"
                      variant="danger"
                      class="w-100"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        size="18"
                      />Eliminar</b-button
                    >
                  </div>
                  <div @click="$refs.fileImg.$el.click()" class="w-100 mt-1">
                    <b-form-file
                      :hidden="true"
                      plain
                      class="form_file"
                      id="inputTagFile"
                      @change="uploadImage"
                      ref="fileImg"
                      accept=".png,.jpg"
                    />
                    <label for="fileImg" class="block cursor-pointer w-100">
                      <div class="text-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="success"
                          size="md"
                          class="button_file w-100"
                        >
                          <feather-icon icon="UploadIcon" size="18" />
                          Subir
                        </b-button>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="name"
            rules="requeridoE"
          >
            <b-form-group label="Nombre" label-for="name">
              <b-form-input
                id="name"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Nombre"
                v-model="items.name"
                autocomplete="off"
              />
              <small
                class="text-danger alert"
                :style="{
                  height: (errors.length > 0 ? 20 : 0) + 'px',
                }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="dni"
            rules="requeridoE"
          >
            <b-form-group label="DNI" label-for="dni">
              <b-form-input
                id="dni"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="DNI"
                v-model="items.dni"
                autocomplete="off"
              />
              <small
                class="text-danger alert"
                :style="{
                  height: (errors.length > 0 ? 20 : 0) + 'px',
                }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="requeridoE|correo"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Email"
                v-model="items.email"
                autocomplete="off"
              />
              <small
                class="text-danger alert"
                :style="{
                  height: (errors.length > 0 ? 20 : 0) + 'px',
                }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <b-form-group v-if="isEdit">
            <div class="d-flex justify-content-between">
              <label for="password">Contraseña</label>
            </div>

            <b-input-group class="input-group-merge">
              <b-form-input
                id="password"
                trim
                v-model="items.password"
                :type="passwordFieldType"
                name="login-password"
                autocomplete="off"
                placeholder="Contraseña"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group v-if="!isEdit">
            <div class="d-flex justify-content-between">
              <label for="password">Contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="requeridoE"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password"
                  trim
                  v-model="items.password"
                  :type="passwordFieldType"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  name="login-password"
                  autocomplete="off"
                  placeholder="Contraseña"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small
                class="text-danger alert"
                :style="{
                  height: (errors.length > 0 ? 20 : 0) + 'px',
                }"
                >{{ errors[0] }}</small
              >
            </validation-provider>
          </b-form-group>

          <div
            v-show="
              log_type == 'Restricciones' ||
              log_type == 'RFI' ||
              log_type == 'Corporativo'
            "
          >
            <h4 for="lesson">Acceso Restricciones</h4>
            <validation-provider #default="{ errors }" name="name">
              <b-form-group label="Perfil" label-for="role">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="role_restriccions"
                  label="text"
                  input-id="role"
                  placeholder="Tipo de Usuario"
                  autocomplete="off"
                  v-model="items.role_restriccion"
                  @input="selectRoleRestriction(items.role_restriccion)"
                  :reduce="(role) => role.value"
                />
              </b-form-group>
            </validation-provider>  
            <validation-provider #default="{ errors }" name="name">
              <b-form-group label="¿Codueños?" label-for="is_show">
                      <div class="d-flex w-50 align-items-center mt-1 ml-1">
                        <span class="mr-2">No</span>
                        <b-form-checkbox v-model="items.is_show" name="is_show" switch>
                        </b-form-checkbox>
                        <span class="ml-2">Sí</span>
                      </div>
              </b-form-group>           
            </validation-provider>             
            <validation-provider #default="{ errors }" name="name">
              <b-form-group
                label="Area"
                label-for="area_owner"
                v-show="items.role_restriccion"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="areas_owner"
                  label="code"
                  input-id="area_owner"
                  :reduce="(areas_owner) => areas_owner.id"
                  placeholder="Area"
                  v-model="items.area_id"
                >
                  <template v-slot:selected-option="option">
                    {{ option.code }} -
                    {{ option.description }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.code }} -
                    {{ option.description }}
                  </template>
                </v-select>
                <small
                  class="text-danger alert"
                  :style="{
                    height: (errors.length > 0 ? 20 : 0) + 'px',
                  }"
                  >{{ errors[0] }}</small
                >
              </b-form-group>
            </validation-provider>     
            <validation-provider
              #default="{ errors }"
              name="phone"
              v-show="showSecondSelect"
            >
              <b-form-group label="Telefono" label-for="phone">
                <b-form-input
                  id="phone"
                  trim
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  placeholder="Telefono"
                  v-model="items.phone"
                />
                <small
                  class="text-danger alert"
                  :style="{
                    height: (errors.length > 0 ? 20 : 0) + 'px',
                  }"
                  >{{ errors[0] }}</small
                >
              </b-form-group>
            </validation-provider>
          </div>     
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-end">
            <b-button
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="isDisabled" small />
              <span v-if="isDisabled" class="px-1">guardando...</span>
              <span v-else>{{ isEdit ? "Actualizar" : "Agregar" }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="[hide(), resetForm()]"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
/* eslint-disable*/
const APIURL = process.env.APIURLFILE;
import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import vSelect from "vue-select";
import UserService from "@/services/UserService";
import AreasService from "@/services/AreasService";
import AreasTemplateService from "@/services/AreasTemplateService";
import ProjectsService from "@/services/ProjectsService";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

export default {
  directives: {
    Ripple,
  },
  components: {
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      ambits: [
        {
          text: "Oficina",
          value: "Oficina",
        },
        {
          text: "Proyecto",
          value: "Proyecto",
        },
      ],
      tempUrl: "",
      apiurl: APIURL,
      sideImg: require("@/assets/images/access/default.png"),
      isEdit: false,
      isDisabled: false,
      records: [],
      urlFile: "",
      items: {
        is_show:false,
        name: "",
        email: "",
        dni: "",
        password: "",
        isSecurity: false,
        photo: null,
        role_restriccion: null,
        type: "001",
        projects_restriccion: 1,
        area_id: null,
        phone: null,
        role_lesson: null,
        isJob: false,
        frecuency: 0,
        ambit: null,
        area_project_name: "",
        project_lesson: null,
        area_name: null,
      },
      showSecondSelect: false,
      showAmbitSelect: false,
      showProjectSelect: false,
      showAreaSelect: false,
      projects: [],
      user_role: JSON.parse(localStorage.getItem("userData")).role_user[0]
        .role_restriction,
      project_id: JSON.parse(localStorage.getItem("project_id")),
      log_type: localStorage.getItem("log_type"),

      temp: {},
      areas: [
        {
          text: "Comunicaciones",
          value: "COM",
        },
        {
          text: "Tecnologías de las Información",
          value: "TI",
        },
        {
          text: "Selección y Desarrollo",
          value: "SEL&DESA",
        },
        {
          text: "Nómina y Relaciones Laborales",
          value: "NOM&RL",
        },
        {
          text: "Responsabilidad Social y Bienestar",
          value: "RRSS",
        },
        {
          text: "Archivo",
          value: "ARCHIV",
        },
        {
          text: "Impuestos y Cuentas por Pagar",
          value: "CXP",
        },
        {
          text: "Finanzas y Seguros",
          value: "FINANZAS",
        },
        {
          text: "Costos y Control de Gestión",
          value: "CO&CG",
        },
        {
          text: "Contabilidad",
          value: "CONTA",
        },
        {
          text: "Logística",
          value: "LOG",
        },
        {
          text: "Almacén Central y Transporte",
          value: "ALM",
        },
        {
          text: "Negocios",
          value: "NEG",
        },
        {
          text: "Propuestas",
          value: "PROP",
        },
        {
          text: "SSOMA",
          value: "SSOMAC",
        },
        {
          text: "Equipos",
          value: "EQUIP",
        },
        {
          text: "Contratos",
          value: "CONTRATOS",
        },
        {
          text: "Legal",
          value: "LEGAL",
        },
        {
          text: "Planeamiento",
          value: "PLANEAM",
        },
      ],
      role_restriccions: [
        {
          value: "user-office",
          text: "Usuario Oficina Principal",
        },
        {
          value: "admin",
          text: "SuperAdministrador",
        },
/*         {
          value: "planner",
          text: "Planner",
        },
        {
          value: "user",
          text: "Usuario",
        }, */
      ],
      role_restriccionsPlanner: [
        {
          value: "planner",
          text: "Planner",
        },
        {
          value: "user",
          text: "Usuario",
        },
      ],
      role_lessons: [
        {
          value: "admin-lesson",
          text: "Administrador",
        },
        {
          value: "reviewer-lesson",
          text: "Revisor",
        },
        {
          value: "user-lesson",
          text: "Aprobador",
        },
      ],
      areas_owner: [],
      project_area_selected: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    async selectProjectArea() {
      console.log('entro aca')
      let filterCoOwner = [];
      this.areas_owner = []
      this.items.area_id == null;
      /* filterCoOwner.push({
        keyContains: "project_id",
        key: "equals",
        value: this.items.projects_restriccion,
      }); */
      filterCoOwner.push({
        keyContains: "type",
        key: "equals",
        value: this.items.type,
      });
     console.log({filterCoOwner})
      const url = `?limit=100&filter=` + JSON.stringify(filterCoOwner);
      const resArea = await AreasService.getAreas(url, this.$store);
      console.log({resArea})
      console.log("url filter coowner", url)
      if (resArea.status) {
        this.areas_owner = resArea.data.rows;
        console.log({areas_owner:this.areas_owner})
      }
    },   
    selectChangeAmbit() {
   
      this.showAreaSelect = false;
      this.showProjectSelect = false;
      this.items.project_lesson = null;
      this.items.area_name = null;
      if (this.items.ambit == "Oficina") this.showAreaSelect = true;
      if (this.items.ambit == "Proyecto") this.showProjectSelect = true;
    },
    uploadChangeAmbit() {
      if (this.items.ambit == "Oficina") this.showAreaSelect = true;
      if (this.items.ambit == "Proyecto") this.showProjectSelect = true;

    
    },
    async editValuesSelection(role_restriction){
      
      if (role_restriction === "user" || role_restriction === "planner") {
        this.showSecondSelect = true;
       
      } else {
        // Si selecciona "Administrador" o algo más, mantener todo oculto.
        this.showSecondSelect = false;
        // También puedes restablecer el valor de otros campos aquí si es necesario.
      }
      if (role_restriction == null) {
        this.items.type = null;
        this.items.projects_restriccion = null;
        this.items.area_id = null;
        this.areas_owner = [];
        this.items.phone = null;
      }
      if (role_restriction === "admin" || role_restriction === "user-office") {
        const url =
          `?limit=100&filter=` +
          JSON.stringify([
            { keyContains: "is_general", key: "equals", value: true },
            { keyContains: "type", key: "equals", value: "004" },
          ]);
        const resArea = await AreasTemplateService.getAreas(url, this.$store);

        if (resArea.status) {
          console.log(809)
          this.areas_owner = resArea.data.rows;
        }
        this.items.type = "004";
      } else {          
      }
    },
    async selectRoleRestriction(role_restriction) {
      this.project_area_selected = null;
      if (role_restriction === "user" || role_restriction === "planner") {
        this.showSecondSelect = true;      
      } else {
        // Si selecciona "Administrador" o algo más, mantener todo oculto.
        this.showSecondSelect = false;
        // También puedes restablecer el valor de otros campos aquí si es necesario.
      }
      if (role_restriction == null) {
        console.log('test');
        this.items.type = null;
        this.items.projects_restriccion = null;
        this.items.area_id = null;
        this.areas_owner = [];
        this.items.phone = null;
      }
      if (role_restriction === "admin" || role_restriction === "user-office") {
        const url =
          `?limit=100&filter=` +
          JSON.stringify([
            { keyContains: "is_general", key: "equals", value: true },
            { keyContains: "type", key: "equals", value: "004" },
          ]);
        const resArea = await AreasTemplateService.getAreas(url, this.$store);

        if (resArea.status) {
        
          this.areas_owner = resArea.data.rows;
          console.log("AREAS OWNER TEMPLATE", this.areas_owner)

        }
        this.items.type = "004";
      } else {       
        this.items.area_id = null;     
      }
    },
    selectRoleLesson(role_lesson) {
    
      this.showAmbitSelect =
        role_lesson === "user-lesson" || role_lesson === "reviewer-lesson";
      if (role_lesson == null) {
        this.items.ambit = null;
      }
    },
    // async selectArea(id) {
    //   let url =
    //     `?limit=100&filter=` +
    //     JSON.stringify([
    //       { keyContains: "project_id", key: "equals", value: id },
    //     ]);
    //   const resp = await AreasService.getAreas(url, this.$store);
    //   console.log("areas", resp);
    //   if (resp.status) {
    //     this.areas_owner = resp.data.rows;
    //   }
    // },
    async getProjects() {
      console.log('entro get projects')
      const url = `?limit=100&`;
      this.showLoading = true;
      const respProyectos = await ProjectsService.getProyectosAll(
        url,
        this.$store
      );
    
      if (respProyectos.status) {
        this.records = respProyectos.data.rows;
        this.projects = respProyectos.data.rows;
       
      }
    },
    async setData(items) {
      console.log("settt data", items);
      console.log("this items receive", this.items);
      if (Object.entries(items).length !== 0) {
     
        this.items.id = items.id;
        this.items.dni = items.dni;
        this.items.email = items.email;
        this.items.password = items.password;
        this.items.name = items.name;
        this.tempUrl = items.photo;
        this.items.isSecurity = items.is_security;
        this.items.role_restriccion = items.role_restriccion;
        this.items.type = items.type;
        this.items.projects_restriccion = items.projects_restriccion;
        this.items.area_id = items.area_owner_id;
        this.project_area_selected = items.project_filter_id
        this.items.phone = items.phone;
        this.items.role_lesson = items.role_lesson;
        this.items.isJob = items.isJob;
        this.items.frecuency = items.frecuency;
        this.items.ambit = items.ambit;
        this.items.project_lesson = items.project_lesson;
        this.items.area_name = items.area_name;
        this.items.area_project_name = items.area_project_name;
        this.items.is_show = items.is_show
        if (items.role_lesson) {
          this.selectRoleLesson(items.role_lesson);
          this.uploadChangeAmbit();
        }
        if (items.role_restriccion){

          await this.editValuesSelection(items.role_restriccion);
          if(this.items.type!='004'){
           
            await this.selectProjectArea(items.projects_restriccion)
          }
        }

        this.isEdit = true;
      } else {
        console.log(938)
        this.items = {
          photo: "",
          name: "",
          email: "",
          dni: "",
          password: null,
          role_restriccion: null,
          area_project_name: "",
          type: '001',
          projects_restriccion: null,
          isSecurity: false,
          area_owner_id: null,
          phone: null,
          role_lesson: null,
          isJob: false,
          frecuency: 0,
          ambit: null,
          project_lesson: null,
          area_name: null,
        };
        this.isEdit = false;
      }
    },
    resetForm() {
      console.log("reset11");
      this.isEdit = false;
      this.tempUrl = "";
      this.items = {
        name: "",
        email: "",
        dni: "",
        password: "",
        isSecurity: false,
        photo: null,
        role_restriccion: null,
        type: "001",
        projects_restriccion: null,
        area_id: null,
        phone: null,
        role_lesson: null,
        isJob: false,
        frecuency: 0,
        ambit: null,
        area_project_name: "",
        project_lesson: null,
        area_name: null,
      };
      this.urlFile = "";
      this.showSecondSelect = false;
      this.showAreaSelect = false;
      this.showProjectSelect = false;
      this.items.project_lesson = null;
      this.showAmbitSelect = false;
      this.items.area_name = null;
      this.$refs.refFormObserver.reset();
    },
    deleteImage(val) {
      this.$refs.fileImg.files = [];
      this.items.photo = null;
      this.urlFile = "";
      this.tempUrl = "";
      // this.delete_image = val
    },
    uploadImage(e) {
      const file = e.target.files[0];
      if (file != undefined) {
        let nameFile = file.name;
        let ext = nameFile.slice(((nameFile.lastIndexOf(".") - 1) >>> 0) + 2);
        if (ext == "png" || ext == "jpg") {
          // this.delete_image = false
          let inputImage = document.getElementById("inputTagFile").files[0];
          cons;
          this.urlFile = URL.createObjectURL(file);
          this.items.photo = inputImage;
        } else {
          this.$swal({
            icon: "error",
            title: "Error!",
            text: "Tipo de archivo no permitido.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      }
    },
    async onSubmit(data) {
     
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          this.items
          this.isDisabled = true;
          let resp = "";
          let datos = new FormData();
          datos.append("dni", data.dni);
          datos.append("name", data.name);
          datos.append("email", data.email);
          if (data.role_restriccion) {
            datos.append("is_restriction", true);
          } else {
            datos.append("is_restriction", false);
          }
          datos.append("role", this.items.role_restriccion);
          datos.append("role_restriction", this.items.role_restriccion);
          datos.append("type", this.items.type);
          datos.append("area_id", this.items.area_id);
          if(this.items.projects_restriccion){
            datos.append("projects", JSON.stringify([this.items.projects_restriccion]))
          }else {
            datos.append("projects", JSON.stringify([]))
          }
         
          // datos.append(
          //   "projects",
          //   JSON.stringify([this.items.projects_restriccion])
          // );
          if (data.is_show) {
            datos.append("is_show", true);
          } else {
            datos.append("is_show", false);
          }
          if (data.role_lesson) {
            datos.append("is_lesson", true);
          } else {
            datos.append("is_lesson", false);
          }
          if (data.isSecurity) {
            datos.append("is_security", true);
          } else {
            datos.append("is_security", false);
          }
          if (data.area_project_name) {
            datos.append("area_project_name", data.area_project_name);
          } else {
            datos.append("area_project_name", null);
          }

          datos.append("role_lesson", this.items.role_lesson);
          datos.append("ambit", this.items.ambit);
          datos.append("area_name", this.items.area_name);
          datos.append("project_lesson", this.items.project_lesson);
          datos.append("phone", this.items.phone);
          datos.append("isJob", this.items.isJob);
          datos.append(
            "frecuency",
            this.items.frecuency ? this.items.frecuency : 0
          );
          if (this.isEdit == false) {
            datos.append("password", data.password);
            datos.append("logged_project_id", this.projects_restriccion);
          

            let dataValidate = {
              email:data.email,
              logged_project_id:this.projects_restriccion
            }

            let valResp = await UserService.validateUser(dataValidate, this.$store);
            console.log({valResp})
            datos.append("validateUser", valResp.data.type);           
            if(valResp.data.type == 3 ){
              this.$swal.fire({
                  title: "Error",
                  text: "Hubo el siguiente error : "+valResp.data.message,
                  icon: "warning",
                  customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,});
            }else {  
              this.$swal({
                  title: "Alerta",
                  text: valResp.data.message,
                  icon: "question",
                  showDenyButton: true,
                  confirmButtonText: "Proceder",
                  denyButtonText: `Cancelar`,
                  customClass: {
                    confirmButton: "btn btn-primary mr-1",
                    denyButton: "btn btn-secondary",
                    margin: "mr-2",
                  },
                  buttonsStyling: false,
                }).then(async(result) => {
                  if (result.isConfirmed) {
                    console.log(1071)
                    //LLAMAR SERVICIO DE MIGRACIÓN
                    resp = await UserService.saveUser(datos, this.$store);
                    console.log(1073)
                    if (resp.status) {
                      this.$swal({
                        title: this.isEdit == true ? "Actualizado" : "Registrado",
                        text:
                          "El usuario ha sido " +
                          (this.isEdit == true ? "actualizado." : "registrado."),
                        icon: "success",
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                      });

                      this.$parent.$parent.$parent.getData();
                      this.$emit("update:is-add", false);
                      this.resetForm();
                    } else {           
                    
                        this.$swal({
                          title: "Error!",
                          text:
                            " Hubo un error al " +
                            (this.isEdit == true ? "actualizar" : "registrar") +
                            " el usuario",
                          icon: "error",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: false,
                        });
                    
                    }
                  }
                });
            }  
          } else {
            let id = data.id;
            if (
              data.password != null &&
              data.password != undefined &&
              data.password != ""
            ) {
              console.log("entro");
              datos.append("password", data.password);
            }
            resp = await UserService.updateUser(id, datos, this.$store);
            if(resp.status){
              this.$swal({
                        title: "Actualizado",
                        text:
                          "El usuario ha sido actualizado" ,                         
                        icon: "success",
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                      });

                      this.$parent.$parent.$parent.getData();
                      this.$emit("update:is-add", false);
                      this.resetForm();
            }else {
              this.$swal({
                          title: "Error!",
                          text:
                            " Hubo un error al actualizar el usuario" ,
                          icon: "error",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: false,
                        });
            }
            console.log(resp)  
          }
      
          this.isDisabled = false;
        } else {
          console.log("no validado");
        }
      });
    },
    async initValues(item){
    
        this.items.type = item.type
        this.items.projects_restriccion = item.projectId

        await this.selectProjectArea(item.projectId)
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
